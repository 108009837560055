import { tv, type VariantProps } from "tailwind-variants";
import { forwardRef, type ReactNode } from "react";

export const stack = tv({
  base: "flex flex-col",
  variants: {
    gap: {
      none: "gap-0",
      xs: "gap-1",
      sm: "gap-2",
      md: "gap-3",
      lg: "gap-5",
      xl: "gap-12",
    },
    align: {
      center: "items-center",
      right: "items-end",
      left: "items-start",
    },
    grow: {
      true: "flex-grow",
    },
    w: {
      full: "w-full",
      auto: "w-auto",
    },
    justify: {
      center: "justify-center",
      apart: "justify-between",
    },
  },
  defaultVariants: {
    align: "left",
    grow: false,
    w: "full",
    gap: "md",
  },
});

interface StackProps extends Partial<VariantProps<typeof stack>> {
  children: ReactNode;
  ariaLabel?: string;
  className?: string;
  onClick?: () => void;
}

const Stack = forwardRef(
  (
    { ariaLabel, children, className, ...props }: StackProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        aria-label={ariaLabel}
        className={`${stack(props)} ${className}`}
        onClick={props.onClick}
      >
        {children}
      </div>
    );
  },
);

export default Stack;
